/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Bar } from 'react-chartjs-2';
import { makeStyles, styled, Box, Grid, Typography } from '@material-ui/core';
import { format, parse } from 'date-fns';

import OpenMoc from 'src/features/openPrc';
import Tab from 'src/components/tab';
import ChartLegend from 'src/components/chartLegend';
import ChartWrapper from 'src/components/chartWrapper';
import TooltipedText from 'src/components/tooltipedText';

import { coloredDatasets, coloredLegend } from 'src/helpers/chartJsHelpers';

const options = {
  maintainAspectRatio: false,
  cornerRadius: 10,
  fullCornerRadius: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          // stepSize: 5,
          padding: 20,
        },
        gridLines: {
          drawTicks: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          padding: 20,
          maxRotation: 0,
          fontColor: '#202239',
          callback: value => {
            const date = parse(value, 'yyyy-MM-dd', new Date());
            const weekDay = format(date, 'EEE');
            const displayValue = format(date, "dd MMM");

            return [weekDay, displayValue];
          },
        },
        gridLines: {
          display: false,
        },
        categoryPercentage: 0.5,
        barPercentage: 0.4,
      },
    ],
  },
  legend: {
    display: false,
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 125,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
    },
  },
  infoWrapper: {
    maxWidth: 700,
    margin: 'auto',
    '& > *': {
      paddingTop: theme.spacing(2),
    },
    '& > *:not(:last-child)': {
      borderRight: '1px solid #EAEDF3',
    },
    [theme.breakpoints.down('sm')]: {
      '& > *:nth-child(1)': {
        borderBottom: '1px solid #EAEDF3',
      },
      '& > *:nth-child(2)': {
        borderRight: 'none',
        borderBottom: '1px solid #EAEDF3',
      },
    },
  },
  text: {
    justifyContent: 'center',
  },
  bodyText: {
    fontSize: 16,
  },
  openMocWrapper: {},
}));

const Span = styled(Box)({
  width: 12,
  height: 12,
  // borderRadius: '50%',
  marginRight: 10,
});

export const OverviewPerformancePanel = ({ data, onExpandToggle }) => {
  const classes = useStyles();

  const { isExpanded, legends, labels, dataSets, totalSet } = data;
  const { leadsPosted, leadsCalled, callsConnected, callsTagged } = totalSet;

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOverview {
        edges {
          node {
            data {
              title2 {
                html
                text
                raw
              }
              tooltip2a {
                html
                text
                raw
              }
              tooltip2b {
                html
                text
                raw
              }
              tooltip2c {
                html
                text
                raw
              }
              tooltip2d {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOverview.edges[0].node.data;

  const datasets = coloredDatasets('bar', {
    labels,
    datasets: dataSets.map(dataset => ({ ...dataset, data: [...dataset?.data] })),
  });

  const colors = coloredLegend(legends);

  const handleExpand = () => {
    onExpandToggle();
  };

  const [open, setOpen] = useState(true)
  useEffect(() => {
    setOpen(false);
    setTimeout(() => {
      setOpen(true);
    }, 200);

  }, [datasets.labels.length]);

  return (
    <>
      <Tab title={prismicData.title2.text} TooltipedText="" drag onClick={handleExpand} expanded={isExpanded}>
        {(datasets.labels.length > 0) && open ? (
          <>
            <Grid container className={classes.infoWrapper}>
              <Grid item xs={6} md>
                <TooltipedText gutterBottom={1} tooltipText={prismicData.tooltip2a.text} position="center">
                  <Span style={{ backgroundColor: colors[0].color }} />
                  <Typography variant="body1" align="center">
                    {leadsPosted.name}
                  </Typography>
                </TooltipedText>
                <Typography variant="h5" align="center" gutterBottom>
                  {leadsPosted.value}
                </Typography>
              </Grid>

              <Grid item xs={6} md>
                <TooltipedText gutterBottom={1} tooltipText={prismicData.tooltip2b.text} position="center">
                  <Span style={{ backgroundColor: colors[1].color }} />
                  <Typography variant="body1" align="center">
                    {leadsCalled.name}
                  </Typography>
                </TooltipedText>
                <Typography variant="h5" align="center" gutterBottom>
                  {leadsCalled.value}
                </Typography>
                <Typography variant="h6" align="center" className={classes.bodyText} gutterBottom>
                  {leadsCalled.percent}%
                </Typography>
              </Grid>

              <Grid item xs={6} md>
                <TooltipedText gutterBottom={1} tooltipText={prismicData.tooltip2c.text} position="center">
                  <Span style={{ backgroundColor: colors[2].color }} />
                  <Typography variant="body1" align="center">
                    {callsConnected.name}
                  </Typography>
                </TooltipedText>
                <Typography variant="h5" align="center" gutterBottom>
                  {callsConnected.value}
                </Typography>
                <Typography variant="h6" align="center" className={classes.bodyText} gutterBottom>
                  {Math.round(callsConnected.value/leadsCalled.value*100)}%
                </Typography>
              </Grid>

              {/* <Grid item xs={6} md>
              <TooltipedText gutterBottom={1} tooltipText={prismicData.tooltip2d.text} position="center">
                <Typography variant="body1" align="center">
                  {callsTagged.name}
                </Typography>
              </TooltipedText>
              <Typography variant="h5" align="center" gutterBottom>
                {callsTagged.value}
              </Typography>
              <Typography variant="h6" align="center" gutterBottom>
                {callsTagged.percent}%
              </Typography>
            </Grid> */}
            </Grid>
            {/* <div>
              <ChartLegend legend={legends} maxWidth={600} />
            </div> */}

            <ChartWrapper count={datasets.labels.length} perItem="100">
              <Bar data={datasets} options={options} height={300} />
            </ChartWrapper>


          </>
        ) : (
          <Typography align="center" variant="body1">
            There is no data for selected date range
          </Typography>
        )}
      </Tab>

      {/* <div className={classes.openMocWrapper}>
        <OpenMoc />
      </div> */}
    </>
  );
};
